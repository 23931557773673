import { GetProps, styled, XStack } from 'tamagui'

export const Button = styled(XStack, {
  name: 'Button',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderRadius: '$1',
  backgroundColor: '$bg',
  borderColor: '$border',
  pressStyle: {
    backgroundColor: '$bgPressed',
  },
  hoverStyle: {
    borderColor: '$borderHovered',
  },
  variants: {
    isIconOnly: {
      true: {
        width: 'fit-content',
        paddingHorizontal: '0px',
        paddingVertical: '0px',
      },
    },
    size: {
      sm: {
        minHeight: '$sm',
        minWidth: '$sm',
        paddingHorizontal: '$1',
        paddingVertical: '$0.5',
      },
      md: {
        minHeight: '$md',
        minWidth: '$md',
        paddingHorizontal: '$1.5',
        paddingVertical: '$1',
      },
      lg: {
        minHeight: '$lg',
        minWidth: '$lg',
        paddingHorizontal: '$2',
        paddingVertical: '$1',
      },
    },
    isSolid: {
      true: {
        backgroundColor: '$bgSolid',
        borderColor: '$borderSolid',
        pressStyle: {
          backgroundColor: '$bgPressed',
          borderColor: '$borderPressedSolid',
        },
        hoverStyle: {
          borderColor: '$borderHoveredSolid',
        },
      },
    },
    isOutlined: {
      true: {
        borderColor: '$borderPressedSolid',
      },
    },
    isDisabled: {
      true: {
        pointerEvents: 'none',
        backgroundColor: '$bgDisabled',
        borderColor: '$hairline',
        cursor: 'not-allowed',
      },
    },
    isTransparent: {
      true: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        hoverStyle: {
          borderColor: 'transparent',
        },
      },
    },
  } as const,
  defaultVariants: {
    size: 'md',
    isIconOnly: false,
    isSolid: false,
    isTransparent: false,
    isDisabled: false,
  },
})

export type ButtonProps = GetProps<typeof Button>
