// Enforces null checks for required client-side NEXT_PUBLIC_* env vars

export enum Env {
  Testnet = 'Testnet',
  Mainnet = 'Mainnet',
}

if (!process.env.NEXT_PUBLIC_ENV) {
  throw new Error('NEXT_PUBLIC_ENV env var is not defined')
}

const rawEnv = process.env.NEXT_PUBLIC_ENV!.toLowerCase()

const getEnv = (): Env => {
  switch (rawEnv) {
    case 'mainnet':
      return Env.Mainnet
    case 'testnet':
    default:
      return Env.Testnet
  }
}

export const env = getEnv()

export const walletConnectCloudProjectId = '940cccb84e9457a47f7a5680d3106e0c'

if (!process.env.NEXT_PUBLIC_ORDERBOOK_WEBSOCKET_URL) {
  throw new Error('NEXT_PUBLIC_ORDERBOOK_WEBSOCKET_URL env var is not defined')
}

export const orderbookWebsocketUrl = process.env.NEXT_PUBLIC_ORDERBOOK_WEBSOCKET_URL!

if (!process.env.NEXT_PUBLIC_ORDERBOOK_API_URL) {
  throw new Error('NEXT_PUBLIC_ORDERBOOK_API_URL env var is not defined')
}

export const orderbookApiUrl = process.env.NEXT_PUBLIC_ORDERBOOK_API_URL!

export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'
export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

export const isMainnet = env === Env.Mainnet
export const isTestnet = env === Env.Testnet

export const isPrivyProd = process.env.NEXT_PUBLIC_PRIVY_ENV === 'production'
